<template>
  <v-dialog
    v-model="dialog"
    width="1000"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        depressed
        small
        color="transparent"
        class="rounded-lg py-5"
        v-bind="attrs"
        v-on="on"
        >
        <img
          src="@/assets/icons/tiantar-form.svg"
          height="36px"
          width="36px"
          ></img>
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="py-2 primary white--text lower-opacity"
        >
        Formulario de Sitio
      </v-card-title>

      <v-card-text
        class="pt-3 lower-background-opacity"
        >
        <v-alert
          v-if="error.active"
          type="error"
          >
          {{ error.message }}
        </v-alert>
        <v-form
          v-model="valid"
          ref="form"
          lazy-validation
          >
          <v-row>
            <template
              v-for="field in fields"
              >
              <v-col
                v-if="field == 'obs'"
                cols="12"
                >
                <v-textarea
                  outlined
                  :label="$t('fields.site.' + field)"
                  v-model.trim="attributes[field]"
                  hide-details
                  dense
                  auto-grow
                  rows="1"
                  style="background-color: white"
                  ></v-textarea>
              </v-col>

              <v-col
                v-else
                cols="12"
                md="4"
                >
                <v-text-field
                  outlined
                  :label="$t('fields.site.' + field)"
                  v-model.trim="attributes[field]"
                  hide-details
                  dense
                  style="background-color: white"
                  :disabled="['siteId', 'name', 'region'].includes(field) && !(currentUser.currentRoleName == 'shibui')"
                  :rules="[
                    v => (['siteId', 'name', 'region'].includes(field) && !!v) || !(['siteId', 'name', 'region'].includes(field)) || 'Campo obligatorio.'
                  ]"
                  ></v-text-field>
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions
        class="pa-2"
        >
        <div
          class="grow text-right"
          >
          <v-btn
            depressed
            color="primary"
            class="rounded-lg py-5"
            :loading="loading"
            :disabled="loading"
            @click="edit"
            >
            Guardar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EditSite } from '@/graphql/mutations/sites'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    valid: true,
    dialog: false,
    loading: false,
    error: {
      active: false,
      message: null
    },
    attributes: {
      siteId: null,
      name: null,
      address: null,
      height: null,
      lng: null,
      lat: null,
      region: null,
      chief: null,
      neighbourhood: null,
      structureType: null
    },

    fields: ["siteId", "name", "address", "height", "lng", "lat", "region", "chief", "neighbourhood", "structureType", "towerOwner", "structureCode", "obs"],
  }),

  props: {
    site: {
      type: Object,
      required: true
    }
  },

  created () {
    this.attributes = {
      ...this.attributes,
      ...this.site
    }

    delete this.attributes.id
    delete this.attributes.__typename
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    edit () {
      if (this.$refs.form.validate()) {
        this.loading = true

        this.attributes.lat = parseFloat(this.attributes.lat)
        this.attributes.lng = parseFloat(this.attributes.lng)

        this.$apollo.mutate({
          mutation: EditSite,
          variables: {
            input: {
              id: this.site.id,
              attributes: this.attributes
            }
          }
        }).then ( res => {
          this.$emit('reload')
          this.dialog = false
          this.loading = false
        }).catch ( err => {
          this.loading = false
          var messages = []

          err.graphQLErrors.forEach ( error => {
            messages.push(this.$t('errors.' + error.extensions.field + '_' + error.extensions.message))
          })

          this.error = {
            active: true,
            message: messages.join(', ')
          }
        })
      }
    }
  }
}
</script>
